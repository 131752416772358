import React from "react"
import {Table} from "react-bulma-components"

class TableComponent extends React.Component {
    render() {
        return (
            <Table className="is-hoverable is-fullwidth">
                <thead>
                <tr>
                    {Object.keys(this.props.data.first()).map((key, index) => <th key={index}>{key}</th>)}
                </tr>
                </thead>
                <tbody>
                {this.props.data.map((row, index) => <tr key={index}>
                        {Object.values(row).map((value, index) => <td key={index}>{value}</td>)}
                    </tr>
                )}
                </tbody>
            </Table>
        )
    }
}

// eslint-disable-next-line no-extend-native
Array.prototype.first = function () {
    if (this.length > 0) {
        return this[0];
    } else {
        return {}
    }
};

export default TableComponent
import React from "react"
import {Element, Section, Container, Columns, Heading} from "react-bulma-components"
import {StaticQuery, graphql} from "gatsby"
import Header from "../../components/Header"
import Page from "../../components/Page"
import AdminMenu from "../../components/Admin/AdminMenu"
import CarrelsComponent from "../../components/Admin/Carrels"

export default () => (
    <>
        <StaticQuery query={graphql`
            query {
                site {
                    siteMetadata {
                        title
                    }
                }
            }
        `} render={(data) => <Header title="Carrels" siteName={data.site.siteMetadata.title}/>}/> <Page>
        <Section>
            <Container>
                <Columns>
                    <Columns.Column narrow>
                        <AdminMenu/>
                    </Columns.Column>
                    <Columns.Column className="has-margin-left-40">
                        <Element className="has-padding-bottom-40">
                            <Heading renderAs="h2" size={2}>Carrels Database</Heading>
                        </Element>
                        <CarrelsComponent/>
                    </Columns.Column>
                </Columns>
            </Container>
        </Section>
    </Page>
    </>
)
import React from "react"
import {Button, Icon} from "react-bulma-components"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faTimes} from "@fortawesome/pro-regular-svg-icons"
import moment from "moment-timezone"

class StudentComponent extends React.Component {
    getItemStyle = (isDragging, draggableStyle) => ({
        background: isDragging && ("rgba(0, 0, 0, 0.3)"),
        ...draggableStyle
    });

    render() {
        return (
            <tr ref={this.props.provided.innerRef} {...this.props.provided.draggableProps} {...this.props.provided.dragHandleProps}
                style={this.getItemStyle(this.props.snapshot.isDragging, this.props.provided.draggableProps.style)}>
                <td>{this.props.student.pickNumber}</td>
                <td>{this.props.pickNumber}</td>
                <td>{this.props.student.netId}</td>
                <td>{this.props.student.classYear}</td>
                {(this.props.picks && this.props.picks.length > 0) ?
                    <td>{moment.tz(this.props.picks.first().start, moment.tz.guess()).add(this.props.student.pickNumber * this.props.picks.first().interval, this.props.picks.first().type.toLowerCase()).format('MM-DD-YYYY hh:mm:ss A zz')}</td> :
                    <td/>}
                <td>
                    <Button color="danger" onClick={() => this.props.removeStudent(this.props.student.netId)}>
                        <Icon>
                            <FontAwesomeIcon icon={faTimes}/>
                        </Icon>
                    </Button>
                </td>
            </tr>
        )
    }
}

// eslint-disable-next-line no-extend-native
Array.prototype.first = function () {
    if (this.length > 0) {
        return this[0];
    } else {
        return {}
    }
};

export default StudentComponent
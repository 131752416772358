import React from "react"
import {Section, Container, Notification, Button} from "react-bulma-components";

export default ({message, error, close}) => (
    <Section style={{position: "absolute", top: 0, left: 0, right: 0}}>
        <Container>
            <Notification color={(error) ? "danger" : "success"}>
                {message}
                <Button remove onClick={close}/>
            </Notification>
        </Container>
    </Section>
)
import React from "react"
import {Button, Dropdown, Form, Icon} from "react-bulma-components";
import AsyncSelect from "react-select/async"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/pro-regular-svg-icons";
import {client} from "../../../feathers";

class CarrelComponent extends React.Component {
    promiseStudents = async (inputValue) => {
        const studentsService = client.service("students");

        let promisedStudents = await studentsService.find({
            query: {
                $sort: {
                    netId: 1
                },
                netId: {
                    $iLike: `%${inputValue}%`
                }
            }
        }).catch((e) => {
            console.log(e);
        });

        return promisedStudents.data.map((student) => {
            return {
                label: student.netId,
                value: student.netId
            }
        });
    };

    render() {
        return (
            <tr>
                <td>{this.props.carrel.number}</td>
                <td>
                    <Dropdown name="status"
                              color={(this.props.carrel.status === "AVAILABLE") ? "info" : (this.props.carrel.status === "RESERVED") ? "warning" : ""}
                              value={this.props.carrel.status}
                              onChange={(value) => this.props.onChange(this.props.index, "status", value)}>
                        <Dropdown.Item value="AVAILABLE" renderAs="a">Available</Dropdown.Item>
                        <Dropdown.Item value="RESERVED" renderAs="a">Reserved</Dropdown.Item>
                    </Dropdown>
                </td>
                <td>
                    <form>
                        <fieldset>
                            <Form.Field>
                                <Form.Control>
                                    <AsyncSelect cacheOptions defaultOptions isClearable
                                                 loadOptions={this.promiseStudents}
                                                 value={(this.props.carrel.studentNetId) ? {
                                                     label: this.props.carrel.studentNetId,
                                                     value: this.props.carrel.studentNetId
                                                 } : null}
                                                 onChange={(selected) => this.props.onChange(this.props.index, "studentNetId", (selected) ? selected.value : "")}/>
                                </Form.Control>
                            </Form.Field>
                        </fieldset>
                    </form>
                </td>
                <td>
                    <Button color="danger"
                            loading={this.props.loading.remove && (this.props.carrel.number === this.props.loading.number)}
                            disabled={this.props.loading.patch || this.props.loading.remove}
                            onClick={() => this.props.removeCarrel(this.props.carrel.number)}>
                        <Icon>
                            <FontAwesomeIcon icon={faTimes}/>
                        </Icon>
                    </Button>
                </td>
            </tr>
        )
    }
}

export default CarrelComponent
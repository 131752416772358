import React from "react"
import {Form, Button} from "react-bulma-components"
import Toast from "../Toast"
import moment from "moment-timezone"
import {client} from "../../../feathers"

class PickComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: false,
            message: null,
            classYear: "3L",
            startDate: "",
            startTime: "",
            interval: 1,
            type: "SECONDS",
            endDate: "",
            endTime: "",
            active: false
        };
    }

    onCloseToast = () => {
        this.setState({
            error: false,
            message: null
        });
    };

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    resetPick = () => {
        this.setState({
            loading: false,
            error: false,
            message: null,
            classYear: "3L",
            startDate: "",
            startTime: "",
            interval: 1,
            type: "SECONDS",
            endDate: "",
            endTime: "",
            active: false
        });
    };

    createPick = () => {
        const picksService = client.service("picks");

        this.setState({
            loading: true
        });

        let pick = {...this.state};

        pick.start = moment.tz(`${pick.startDate} ${pick.startTime}`, moment.tz.guess()).format('YYYY-MM-DDTHH:mm:ssZZ');
        pick.end = moment.tz(`${pick.endDate} ${pick.endTime}`, moment.tz.guess()).format('YYYY-MM-DDTHH:mm:ssZZ');

        Promise.all([
            picksService.create(pick)
        ]).then(([picksResult]) => {
            this.setState({
                message: `Successfully created a new pick at ${picksResult.datetime}`
            });
        }).catch((e) => {
            this.setState({
                error: true,
                message: e.message
            });
        }).finally(() => {
            this.setState({
                loading: false
            });
        });
    };

    render() {
        return (
            <>
                <form className="is-flex justify-center" onSubmit={(e) => e.preventDefault()}>
                    <fieldset>
                        <Form.Field kind="group">
                            <Form.Control>
                                <Form.Label>Class Year</Form.Label>
                                <Form.Select name="classYear" value={this.state.classYear}
                                             onChange={(e) => this.onChange(e)}>
                                    <option value="3L">3L</option>
                                    <option value="2L">2L</option>
                                    <option value="1L">1L</option>
                                    <option value="LLM">LLM</option>
                                </Form.Select>
                            </Form.Control>
                            <Form.Control>
                                <Form.Label>Start Date</Form.Label>
                                <Form.Input type="date" name="startDate" value={this.state.startDate}
                                            onChange={(e) => this.onChange(e)}/>
                            </Form.Control>
                            <Form.Control>
                                <Form.Label>Start Time</Form.Label>
                                <Form.Input type="time" name="startTime" value={this.state.startTime}
                                            onChange={(e) => this.onChange(e)}/>
                            </Form.Control>
                            <Form.Control>
                                <Form.Label>End Date</Form.Label>
                                <Form.Input type="date" name="endDate" value={this.state.endDate}
                                            onChange={(e) => this.onChange(e)}/>
                            </Form.Control>
                            <Form.Control>
                                <Form.Label>End Time</Form.Label>
                                <Form.Input type="time" name="endTime" value={this.state.endTime}
                                            onChange={(e) => this.onChange(e)}/>
                            </Form.Control>
                            <Form.Field className="has-addons">
                                <Form.Control>
                                    <Form.Label>Interval</Form.Label>
                                    <Form.Input type="number" min={1} name="interval" value={this.state.interval}
                                                onChange={(e) => this.onChange(e)}/>
                                </Form.Control>
                                <Form.Control>
                                    <Form.Label>Type</Form.Label>
                                    <Form.Select name="type" value={this.state.type} onChange={(e) => this.onChange(e)}>
                                        <option value="SECONDS">Seconds</option>
                                        <option value="MINUTES">Minutes</option>
                                        <option value="HOURS">Hours</option>
                                        <option value="DAYS">Days</option>
                                    </Form.Select>
                                </Form.Control>
                            </Form.Field>
                        </Form.Field>
                        <Form.Field className="is-flex justify-center">
                            <Form.Control>
                                <Form.Checkbox name="active" checked={this.state.active}
                                               onChange={(e) => this.onChange({
                                                   target: {
                                                       name: e.target.name,
                                                       value: e.target.checked
                                                   }
                                               })}><span className="has-padding-left-5">Active</span></Form.Checkbox>
                            </Form.Control>
                        </Form.Field>
                        <Form.Field className="justify-center" kind="group">
                            <Form.Control>
                                <Button type="button" onClick={this.resetPick}>Reset</Button>
                            </Form.Control>
                            <Form.Control>
                                <Button type="button" color="success" onClick={this.createPick}>Save</Button>
                            </Form.Control>
                        </Form.Field>
                    </fieldset>
                </form>
                {this.state.message &&
                <Toast message={this.state.message} error={this.state.error} close={this.onCloseToast}/>}
            </>
        )
    }
}

export default PickComponent
import React from "react"
import {Columns, Table, Button, Pagination} from "react-bulma-components"
import Loading from "../../components/Page/Loading"
import Carrel from "./Carrels/Carrel"
import Toast from "./Toast"
import {client} from "../../feathers"

class CarrelsComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            error: false,
            message: null,
            carrels: null
        };
    }

    getCarrels = () => {
        const carrelsService = client.service("carrels");

        Promise.all([
            carrelsService.find({
                query: {
                    $skip: (this.state.carrels && this.state.carrels.skip) ? (this.state.carrels.skip < (this.state.carrels.total - 1)) ? this.state.carrels.skip : (this.state.carrels.skip - this.state.carrels.limit) : 0,
                    $sort: {
                        number: 1
                    },
                    include: true
                }
            })
        ]).then(([carrelsResult]) => {
            let carrels = carrelsResult;

            carrels.data = carrels.data.map(({number, status, studentNetId}) => {
                return {
                    number,
                    status,
                    studentNetId: (studentNetId) ? studentNetId : ""
                }
            });

            this.setState({
                carrels
            });
        }).catch((e) => {
            this.setState({
                error: true,
                message: e.message
            });
        });
    };

    patchCarrels = () => {
        const carrelsService = client.service("carrels");
        let errors = [];

        this.setState({
            loading: true,
            error: false,
            message: null
        });

        for (let i = 0; i < this.state.carrels.data.length; i++) {
            Promise.all([
                carrelsService.patch(this.state.carrels.data[i].number, {
                    status: this.state.carrels.data[i].status,
                    studentNetId: (this.state.carrels.data[i].studentNetId) ? this.state.carrels.data[i].studentNetId : null
                })
            ]).catch((e) => {
                console.log(e);
                errors.push({
                    error: true,
                    message: e.message
                });
            });
        }

        this.setState({
            loading: false,
            message: `Updated ${this.state.carrels.data.length} records ${(errors.length > 0) ? <ul>
                {errors.map((error) => <li>{error.message}</li>)}
            </ul> : ""}`
        });
    };

    removeCarrel = (number) => {
        const carrelsService = client.service("carrels");

        this.setState({
            loading: true,
            error: false,
            message: null
        });

        Promise.all([
            carrelsService.remove(number)
        ]).then(([carrelResult]) => {
            this.setState({
                message: `Successfully removed carrel '${carrelResult.number}' from the database`
            });
        }).catch((e) => {
            this.setState({
                error: true,
                message: e.message
            });
        }).finally(() => {
            this.setState({
                loading: false
            });
        });
    };

    onCloseToast = () => {
        this.setState({
            error: false,
            message: null
        });
    };

    onChange = (index, name, value) => {
        let carrels = JSON.parse(JSON.stringify(this.state.carrels));

        carrels.data[index][name] = (value) ? value : "";

        this.setState({
            carrels
        });
    };

    onPageChange = (pageNumber) => {
        const carrelsService = client.service("carrels");

        Promise.all([
            carrelsService.find({
                query: {
                    $skip: (pageNumber - 1) * this.state.carrels.limit,
                    $sort: {
                        number: 1
                    },
                    include: true
                }
            })
        ]).then(([carrelsResult]) => {
            let carrels = carrelsResult;

            carrels.data = carrels.data.map(({number, status, studentNetId}) => {
                return {
                    number,
                    status,
                    studentNetId: (studentNetId) ? studentNetId : ""
                }
            });

            this.setState({
                carrels
            });
        }).catch((e) => {
            console.log(e);
        });
    };

    componentDidMount() {
        const carrelsService = client.service("carrels");

        Promise.all([
            carrelsService.find({
                query: {
                    $sort: {
                        number: 1
                    },
                    include: true
                }
            })
        ]).then(([carrelsResult]) => {
            let carrels = carrelsResult;

            carrels.data = carrels.data.map(({number, status, studentNetId}) => {
                return {
                    number,
                    status,
                    studentNetId: (studentNetId) ? studentNetId : ""
                }
            });

            this.setState({
                carrels
            });
        }).catch((e) => {
            this.setState({
                error: true,
                message: e.message
            });
        }).finally(() => {
            this.setState({
                loading: false
            });
        });

        carrelsService.on("created", () => this.getCarrels());
        carrelsService.on("patched", () => this.getCarrels());
        carrelsService.on("updated", () => this.getCarrels());
        carrelsService.on("removed", () => this.getCarrels());
    }

    render() {
        return (
            (this.state.loading) ? <Loading/> : <>
                {(this.state.carrels && this.state.carrels.data && this.state.carrels.data.length > 0) ? <>
                    <Columns>
                        <Columns.Column>
                            <Table className="is-hoverable is-fullwidth">
                                <thead>
                                <tr>
                                    {Object.keys((({number, status}) => ({
                                        number,
                                        status
                                    }))(this.state.carrels.data.first())).map((key, index) => <th
                                        key={index}>{key}</th>)}
                                    <th>studentNetId</th>
                                    <th/>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.carrels.data.map((carrel, index) => <Carrel key={index} index={index}
                                                                                        carrel={carrel}
                                                                                        loading={this.state.loading}
                                                                                        removeCarrel={this.removeCarrel}
                                                                                        onChange={this.onChange}/>)}
                                </tbody>
                            </Table>
                        </Columns.Column>
                    </Columns>
                    <Columns>
                        <Columns.Column>
                            <Pagination current={this.state.carrels.skip / this.state.carrels.limit + 1}
                                        total={Math.ceil(this.state.carrels.total / this.state.carrels.limit)} delta={1}
                                        onChange={this.onPageChange}/>
                        </Columns.Column>
                    </Columns>
                    <Columns className="is-centered">
                        <Columns.Column narrow>
                            <Button color="success"
                                    loading={this.state.loading}
                                    disabled={this.state.loading}
                                    onClick={() => this.patchCarrels()}>Save</Button>
                        </Columns.Column>
                    </Columns>
                    {this.state.message &&
                    <Toast message={this.state.message} error={this.state.error} close={this.onCloseToast}/>}
                </> : <p><em>There aren't any carrels in the database</em></p>}
            </>
        )
    }
}

// eslint-disable-next-line no-extend-native
Array.prototype.first = function () {
    if (this.length > 0) {
        return this[0];
    } else {
        return {}
    }
};

export default CarrelsComponent
import React from "react"
import {Button, Form, Heading} from "react-bulma-components"
import CSVReader from "@byu-law/react-csv-reader"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faUpload} from "@fortawesome/pro-regular-svg-icons"

class UploaderComponent extends React.Component {
    render() {
        const parserOptions = {
            header: true,
            dynamicTyping: true,
            skipEmptyLines: true
        };

        return (
            <>
                <Heading className="has-text-centered" renderAs="h4" size={4}
                         subtitle>Import {this.props.type}</Heading>
                <form className="is-flex justify-center" onSubmit={(e) => e.preventDefault()}>
                    <fieldset>
                        <Form.Field kind="group">
                            <Form.Control>
                                <CSVReader cssClass="file justify-center" labelClass="file-label"
                                           cssInputClass="file-input" parserOptions={parserOptions}
                                           onFileLoaded={this.props.onFileLoaded}>
                                    <span className="file-cta">
                                        <span className="file-icon">
                                            <FontAwesomeIcon icon={faUpload}/>
                                        </span>
                                        <span className="file-label">Import {this.props.type} CSV</span>
                                    </span>
                                </CSVReader>
                            </Form.Control>
                            <Form.Control className="is-flex justify-center">
                                <Button color="danger" type="button" name="show" loading={this.props.loading}
                                        disabled={this.props.loading}
                                        onClick={(e) => this.props.onToggle(e)}>Clear {this.props.type} Database</Button>
                            </Form.Control>
                        </Form.Field>
                    </fieldset>
                </form>
            </>
        )
    }
}

export default UploaderComponent
import React from "react"
import Loading from "../components/Page/Loading"
import Login from "./student/login"
import Forbidden from "../components/403"

class AdminRoute extends React.Component {
    render() {
        const {authenticationState, component: Component, location, ...rest} = this.props;

        if (!authenticationState || authenticationState.isLoading) {
            return <Loading/>
        } else if (authenticationState && !authenticationState.isSignedIn) {
            return <Login/>
        } else if (!authenticationState.user.permissions.includes("ADMIN")) {
            return <Forbidden/>
        } else {
            return <Component {...rest} />
        }
    }
}

export default AdminRoute
import React from "react"
import {Element, Section, Container, Columns, Heading, Icon} from "react-bulma-components"
import {StaticQuery, graphql} from "gatsby"
import {CSVLink} from "react-csv"
import Header from "../../../components/Header"
import Page from "../../../components/Page"
import Loading from "../../../components/Page/Loading"
import AdminMenu from "../../../components/Admin/AdminMenu"
import Toast from "../../../components/Admin/Toast"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faDownload} from "@fortawesome/pro-regular-svg-icons"
import {client} from "../../../feathers"

class UploadRoute extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            error: false,
            message: null,
            show: false,
            carrels: []
        };
    }

    getCarrels = async () => {
        const carrelsService = client.service("carrels");
        let carrels = [];
        let initialResult = await carrelsService.find().catch((e) => {
            console.log(e);
        });
        let limit = initialResult.limit;
        let total = initialResult.total;
        let pages = Math.ceil(total / limit);

        this.setState({
            loading: true
        });

        for (let i = 0; i < pages; i++) {
            let carrelsResult = await carrelsService.find({
                query: {
                    $limit: limit,
                    $skip: i * limit,
                    $sort: {
                        number: 1
                    },
                    include: true
                }
            }).catch((e) => {
                console.log(e);
            });

            carrels = carrels.concat(carrelsResult.data);
        }

        this.setState({
            loading: false
        });

        return carrels;
    };

    onCloseToast = () => {
        this.setState({
            error: false,
            message: null
        });
    };

    componentDidMount() {
        this.getCarrels().then((carrels) => {
            carrels = carrels.map(({number, status, studentNetId: netId, student}) => {
                return {
                    number,
                    status,
                    netId,
                    givenName: (student) ? student.givenName : null,
                    familyName: (student) ? student.familyName : null
                }
            });

            this.setState({
                carrels
            });
        }).catch((e) => {
            console.log(e);
        });
    }

    render() {
        const query = graphql`
            query {
                site {
                    siteMetadata {
                        title
                    }
                }
            }
        `;

        return (
            <>
                <StaticQuery query={query} render={(data) => <Header title="Carrels Export"
                                                                     siteName={data.site.siteMetadata.title}/>}/>
                <Page>
                    <Section>
                        <Container>
                            <Columns>
                                <Columns.Column narrow>
                                    <AdminMenu/>
                                </Columns.Column>
                                <Columns.Column className="has-margin-left-40">
                                    <Element className="has-padding-bottom-40">
                                        <Heading renderAs="h2" size={2}>Carrels Export</Heading>
                                    </Element>
                                    {(this.state.loading) ?
                                        <Loading/> : (this.state.carrels && this.state.carrels.length > 0) &&
                                        <CSVLink className="button" data={this.state.carrels} filename="carrels.csv"
                                                 disabled={this.state.loading}>
                                            <Icon>
                                                <FontAwesomeIcon icon={faDownload}/>
                                            </Icon>
                                            <span>Export</span>
                                        </CSVLink>}
                                </Columns.Column>
                            </Columns>
                        </Container>
                    </Section>
                    {this.state.message &&
                    <Toast message={this.state.message} error={this.state.error} close={this.onCloseToast}/>}
                </Page>
            </>
        )
    }
}

export default UploadRoute
import React from "react"
import {Form, Button} from "react-bulma-components"
import moment from "moment-timezone"

class PickComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            classYear: this.props.pick.classYear,
            startDate: moment.tz(this.props.pick.start, moment.tz.guess()).format('YYYY-MM-DD'),
            startTime: moment.tz(this.props.pick.start, moment.tz.guess()).format('HH:mm'),
            interval: this.props.pick.interval,
            type: this.props.pick.type,
            endDate: moment.tz(this.props.pick.end, moment.tz.guess()).format('YYYY-MM-DD'),
            endTime: moment.tz(this.props.pick.end, moment.tz.guess()).format('HH:mm'),
            active: this.props.pick.active
        };
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    resetPick = () => {
        this.setState({
            classYear: this.props.pick.classYear,
            startDate: moment.tz(this.props.pick.start, moment.tz.guess()).format('YYYY-MM-DD'),
            startTime: moment.tz(this.props.pick.start, moment.tz.guess()).format('HH:mm'),
            interval: this.props.pick.interval,
            type: this.props.pick.type,
            endDate: moment.tz(this.props.pick.end, moment.tz.guess()).format('YYYY-MM-DD'),
            endTime: moment.tz(this.props.pick.end, moment.tz.guess()).format('HH:mm'),
            active: this.props.pick.active
        });
    };

    updatePick = () => {

    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(this.props.pick) !== JSON.stringify(prevProps.pick)) {
            this.setState({
                pick: this.props.pick
            });
        }
    }

    render() {
        return (
            <form className="is-flex justify-center" onSubmit={(e) => e.preventDefault()}>
                <fieldset>
                    <Form.Field kind="group">
                        <Form.Control>
                            <Form.Label>Class Year</Form.Label>
                            <Form.Select name="classYear" value={this.state.classYear}
                                         onChange={(e) => this.onChange(e)}>
                                <option value="3L">3L</option>
                                <option value="2L">2L</option>
                                <option value="1L">1L</option>
                                <option value="LLM">LLM</option>
                            </Form.Select>
                        </Form.Control>
                        <Form.Control>
                            <Form.Label>Start Date</Form.Label>
                            <Form.Input type="date" name="startDate" value={this.state.startDate}
                                        onChange={(e) => this.onChange(e)}/>
                        </Form.Control>
                        <Form.Control>
                            <Form.Label>Start Time</Form.Label>
                            <Form.Input type="time" name="startTime" value={this.state.startTime}
                                        onChange={(e) => this.onChange(e)}/>
                        </Form.Control>
                        <Form.Control>
                            <Form.Label>End Date</Form.Label>
                            <Form.Input type="date" name="endDate" value={this.state.endDate}
                                        onChange={(e) => this.onChange(e)}/>
                        </Form.Control>
                        <Form.Control>
                            <Form.Label>End Time</Form.Label>
                            <Form.Input type="time" name="endTime" value={this.state.endTime}
                                        onChange={(e) => this.onChange(e)}/>
                        </Form.Control>
                        <Form.Field className="has-addons">
                            <Form.Control>
                                <Form.Label>Interval</Form.Label>
                                <Form.Input type="number" min={1} name="interval" value={this.state.interval}
                                            onChange={(e) => this.onChange(e)}/>
                            </Form.Control>
                            <Form.Control>
                                <Form.Label>Type</Form.Label>
                                <Form.Select name="type" value={this.state.type} onChange={(e) => this.onChange(e)}>
                                    <option value="SECONDS">Seconds</option>
                                    <option value="MINUTES">Minutes</option>
                                    <option value="HOURS">Hours</option>
                                    <option value="DAYS">Days</option>
                                </Form.Select>
                            </Form.Control>
                        </Form.Field>
                    </Form.Field>
                    <Form.Field className="is-flex justify-center">
                        <Form.Control>
                            <Form.Checkbox name="active" checked={this.state.active} onChange={(e) => this.onChange({
                                target: {
                                    name: e.target.name,
                                    value: e.target.checked
                                }
                            })}><span className="has-padding-left-5">Active</span></Form.Checkbox>
                        </Form.Control>
                    </Form.Field>
                    <Form.Field className="justify-center" kind="group">
                        <Form.Control>
                            <Button type="button" onClick={this.resetPick}>Reset</Button>
                        </Form.Control>
                        <Form.Control>
                            <Button type="button" color="success" onClick={this.updatePick}>Update</Button>
                        </Form.Control>
                    </Form.Field>
                </fieldset>
            </form>
        )
    }
}

export default PickComponent
import React from "react"
import {Columns} from "react-bulma-components"
import Loading from "../Page/Loading"
import Pick from "./Picks/Pick"
import CreatePick from "./Picks/CreatePick"
import {client} from "../../feathers"

class PicksComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: false,
            message: null,
            picks: null
        };
    }

    getPicks = () => {
        const picksService = client.service("picks");

        this.setState({
            loading: true
        });

        Promise.all([
            picksService.find({
                query: {
                    $sort: {
                        classYear: -1
                    }
                }
            })
        ]).then(([picksResult]) => {
            const picks = picksResult;

            this.setState({
                picks
            });
        }).catch((e) => {
            this.setState({
                error: true,
                message: e.message
            });
        }).finally(() => {
            this.setState({
                loading: false
            });
        });
    };

    componentDidMount() {
        const picksService = client.service("picks");

        this.getPicks();

        picksService.on("created", () => this.getPicks());
        picksService.on("patched", () => this.getPicks());
        picksService.on("updates", () => this.getPicks());
        picksService.on("removed", () => this.getPicks());
    }

    render() {
        return (
            (this.state.loading) ? <Loading/> : <>
                {(this.state.picks && this.state.picks.data && this.state.picks.data.length > 0) && this.state.picks.data.map((pick, index) =>
                    <Columns>
                        <Columns.Column>
                            <Pick key={index} pick={pick}/>
                        </Columns.Column>
                    </Columns>)}
                <Columns>
                    <Columns.Column>
                        <CreatePick/>
                    </Columns.Column>
                </Columns>
            </>
        )
    }
}

export default PicksComponent
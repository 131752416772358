import React from "react"
import {Element, Section, Container, Columns, Heading, Table, Tag} from "react-bulma-components"
import {StaticQuery, graphql} from "gatsby"
import Header from "../../components/Header"
import Page from "../../components/Page"
import Loading from "../../components/Page/Loading"
import AdminMenu from "../../components/Admin/AdminMenu"
import moment from "moment-timezone"
import {client} from "../../feathers"

class IndexRoute extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            error: false,
            message: null,
            picks: null
        };
    }

    componentDidMount() {
        const picksService = client.service("picks");

        Promise.all([
            picksService.find()
        ]).then(([picksResult]) => {
            const picks = picksResult;

            this.setState({
                picks
            });
        }).catch((e) => {
            this.setState({
                error: true,
                message: e.message
            });
        }).finally(() => {
            this.setState({
                loading: false
            });
        });
    }

    render() {
        return (
            <>
                <StaticQuery query={graphql`
            query {
                site {
                    siteMetadata {
                        title
                    }
                }
            }
        `} render={(data) => <Header title="Admin" siteName={data.site.siteMetadata.title}/>}/> <Page>
                {(this.state.loading) ? <Loading/> : <Section>
                    <Container>
                        <Columns>
                            <Columns.Column narrow>
                                <AdminMenu/>
                            </Columns.Column>
                            <Columns.Column className="has-margin-left-40">
                                <Element className="has-padding-bottom-40">
                                    <Heading renderAs="h2" size={2}>Carrel Picks Stats</Heading>
                                </Element>
                                {(this.state.picks && this.state.picks.data && this.state.picks.data.length > 0) ?
                                    <Table>
                                        <thead>
                                        <tr>
                                            <th>Class Year</th>
                                            <th>Start Datetime</th>
                                            <th>End Datetime</th>
                                            <th>Active</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.picks.data.map((pick, index) => <tr key={index}>
                                            <td>{pick.classYear}</td>
                                            <td>{moment.tz(pick.start, moment.tz.guess()).format('YYYY-MM-DDTHH:mm zz')}</td>
                                            <td>{moment.tz(pick.end, moment.tz.guess()).format('YYYY-MM-DDTHH:mm zz')}</td>
                                            <td><Tag
                                                color={(pick && pick.active) ? "success" : "danger"}>{(pick && pick.active) ? "True" : "False"}</Tag>
                                            </td>
                                        </tr>)}
                                        </tbody>
                                    </Table> : <p><em>There aren't any picks in the database</em></p>}
                            </Columns.Column>
                        </Columns>
                    </Container>
                </Section>}
            </Page>
            </>
        )
    }
}

export default IndexRoute
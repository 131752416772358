import React from "react"
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd"
import {Columns, Form, Table, Button, Pagination} from "react-bulma-components"
import Loading from "../../components/Page/Loading"
import Student from "./Students/Student"
import Toast from "./Toast"
import {client} from "../../feathers"

class StudentsComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            error: false,
            message: null,
            classYear: "3L",
            students: null,
            picks: null,
            draggingRowId: null
        };
    }

    getStudents = () => {
        const studentsService = client.service("students");

        this.setState({
            loading: true
        });

        Promise.all([
            studentsService.find({
                query: {
                    $skip: (this.state.students && this.state.students.skip) ? (this.state.students.skip < (this.state.students.total - 1)) ? this.state.students.skip : (this.state.students.skip - this.state.students.limit) : 0,
                    $sort: {
                        pickNumber: 1
                    },
                    classYear: this.state.classYear
                }
            })
        ]).then(([studentsResult]) => {
            const students = studentsResult;

            this.setState({
                students
            });
        }).catch((e) => {
            this.setState({
                error: true,
                message: e.message
            });
        }).finally(() => {
            this.setState({
                loading: false
            });
        });
    };

    patchStudents = () => {
        const studentServices = client.service("students");
        const students = this.state.students.data.map((student, index) => {
            return {
                pickNumber: index + this.state.students.skip + 1,
                netId: student.netId,
                classYear: student.classYear
            }
        });

        this.setState({
            loading: true,
            error: false,
            message: null
        });

        for (let i = 0; i < students.length; i++) {
            studentServices.patch(students[i].netId, students[i]).then((result) => {
                this.setState({
                    message: `Successfully patched '${result.netId}' in the database`
                });
            }).catch((e) => {
                this.setState({
                    error: true,
                    message: e.message
                });
            });
        }

        this.setState({
            loading: false
        });
    };

    removeStudent = (netId) => {
        const studentsService = client.service("students");

        this.setState({
            loading: true,
            error: false,
            message: null
        });

        Promise.all([
            studentsService.remove(netId)
        ]).then(([studentResult]) => {
            this.setState({
                message: `Successfully removed '${studentResult.netId}' from the database`
            });
        }).catch((e) => {
            this.setState({
                error: true,
                message: e.message
            });
        }).finally(() => {
            this.setState({
                loading: false
            });
        });
    };

    resetStudents = () => {
        this.setState({
            students: this.props.students
        });
    };

    onCloseToast = () => {
        this.setState({
            error: false,
            message: null
        });
    };

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            this.getStudents();
        });
    };

    onPageChange = (pageNumber) => {
        const studentsService = client.service("students");

        Promise.all([
            studentsService.find({
                query: {
                    $skip: (pageNumber - 1) * this.state.students.limit,
                    $sort: {
                        pickNumber: 1
                    },
                    classYear: this.state.classYear
                }
            })
        ]).then(([studentsResult]) => {
            const students = studentsResult;

            this.setState({
                students
            });
        }).catch((e) => {
            console.log(e);
        });
    };

    onDragEnd = (result) => {
        const {destination, source, reason} = result;

        if (!destination || reason === "CANCEL") {
            this.setState({
                draggingRowId: null
            });
        } else if (!((destination.droppableId === source.droppableId) && (destination.index === source.index))) {
            const students = JSON.parse(JSON.stringify(this.state.students));
            let student = this.state.students.data[source.index];

            students.data.splice(source.index, 1);
            students.data.splice(destination.index, 0, student);

            this.setState({
                students
            });
        }
    };

    componentDidMount() {
        const studentsService = client.service("students");
        const picksService = client.service("picks");

        Promise.all([
            studentsService.find({
                query: {
                    $sort: {
                        pickNumber: 1
                    },
                    classYear: this.state.classYear
                }
            }),
            picksService.find()
        ]).then(([studentsResult, picksResult]) => {
            const students = studentsResult;
            const picks = picksResult;

            this.setState({
                students,
                picks
            });
        }).catch((e) => {
            this.setState({
                error: true,
                message: e.message
            });
        }).finally(() => {
            this.setState({
                loading: false
            });
        });

        studentsService.on("created", () => this.getStudents());
        studentsService.on("patched", () => this.getStudents());
        studentsService.on("updated", () => this.getStudents());
        studentsService.on("removed", () => this.getStudents());
    }

    render() {
        return (
            (this.state.loading) ? <Loading/> : <>
                <Columns>
                    <Columns.Column>
                        <form onSubmit={(e) => e.preventDefault()}>
                            <fieldset>
                                <Form.Field kind="group">
                                    <Form.Control>
                                        <Form.Label>Class Year</Form.Label>
                                        <Form.Select name="classYear" value={this.state.classYear}
                                                     onChange={(e) => this.onChange(e)}>
                                            <option value="3L">3L</option>
                                            <option value="2L">2L</option>
                                            <option value="1L">1L</option>
                                            <option value="LLM">LLM</option>
                                        </Form.Select>
                                    </Form.Control>
                                </Form.Field>
                            </fieldset>
                        </form>
                    </Columns.Column>
                </Columns>
                {(this.state.students && this.state.students.data && this.state.students.data.length) ? <>
                    <Columns>
                        <Columns.Column>
                            <DragDropContext onDragEnd={this.onDragEnd}>
                                <Table className="is-hoverable is-fullwidth">
                                    <thead>
                                    <tr>
                                        <th>oldPickNumber</th>
                                        {Object.keys((({pickNumber: newPickNumber, netId, classYear}) => ({
                                            newPickNumber,
                                            netId,
                                            classYear
                                        }))(this.state.students.data.first())).map((key, index) => <th
                                            key={index}>{key}</th>)}
                                        {(this.state.picks && this.state.picks.data && this.state.picks.data.length > 0) &&
                                        <th>pickTime</th>}
                                        <th/>
                                    </tr>
                                    </thead>
                                    <Droppable droppableId="table">
                                        {(provided, snapshot) => (
                                            <tbody ref={provided.innerRef} {...provided.droppableProps}>
                                            {this.state.students.data.map((student, index) => <Draggable
                                                draggableId={student.netId}
                                                index={index}
                                                key={student.netId}
                                            >
                                                {(provided, snapshot) => (
                                                    <Student pickNumber={index + this.state.students.skip + 1}
                                                             student={student}
                                                             picks={this.state.picks.data.filter((element) => element.classYear === student.classYear)}
                                                             removeStudent={this.removeStudent} provided={provided}
                                                             snapshot={snapshot}/>
                                                )}
                                            </Draggable>)}
                                            {provided.placeholder}
                                            </tbody>
                                        )}
                                    </Droppable>
                                </Table>
                            </DragDropContext>
                        </Columns.Column>
                    </Columns>
                    <Columns>
                        <Columns.Column>
                            <Pagination current={this.state.students.skip / this.state.students.limit + 1}
                                        total={Math.ceil(this.state.students.total / this.state.students.limit)}
                                        delta={1}
                                        onChange={this.onPageChange}/>
                        </Columns.Column>
                    </Columns>
                    <Columns>
                        <Columns.Column>
                            <form className="is-flex justify-center" onSubmit={(e) => e.preventDefault()}>
                                <fieldset>
                                    <Form.Field kind="group">
                                        <Form.Control>
                                            <Button type="button" onClick={this.resetStudents}>Reset</Button>
                                        </Form.Control>
                                        <Form.Control>
                                            <Button type="button" color="success"
                                                    onClick={this.patchStudents}>Save</Button>
                                        </Form.Control>
                                    </Form.Field>
                                </fieldset>
                            </form>
                        </Columns.Column>
                    </Columns>
                    {this.state.message &&
                    <Toast message={this.state.message} error={this.state.error} close={this.onCloseToast}/>}
                </> : <p><em>There aren't any students in the selected class year in the database</em></p>}
            </>
        )
    }
}

// eslint-disable-next-line no-extend-native
Array.prototype.first = function () {
    if (this.length > 0) {
        return this[0];
    } else {
        return {}
    }
};

export default StudentsComponent
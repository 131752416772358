import React from "react"
import {Modal, Content, Form, Button} from "react-bulma-components"

export default ({loading, title, content, confirm, show, onCancel, onConfirm}) => (
    <Modal name="clear" show={show} onClose={onCancel}>
        <Modal.Card>
            <Modal.Card.Head onClose={onCancel}>
                <Modal.Card.Title>{title}</Modal.Card.Title>
            </Modal.Card.Head>
            <Modal.Card.Body>
                <Content>{content}</Content>
            </Modal.Card.Body>
            <Modal.Card.Foot>
                <form onSubmit={(e) => e.preventDefault()}>
                    <fieldset>
                        <Form.Field kind="group">
                            <Form.Control>
                                <Button type="button" onClick={onCancel}>Cancel</Button>
                            </Form.Control>
                            <Form.Control>
                                <Button color="danger" type="button" loading={loading} disabled={loading}
                                        onClick={onConfirm}>{confirm}</Button>
                            </Form.Control>
                        </Form.Field>
                    </fieldset>
                </form>
            </Modal.Card.Foot>
        </Modal.Card>
    </Modal>
)
import React from "react"
import {Menu} from "react-bulma-components"
import {Link} from "gatsby"

export default () => (
    <Menu>
        <Menu.List title="Admin">
            <Menu.List.Item renderAs="div">
                <Link className={(String(window.location.pathname) === "/admin") ? "is-active" : ""}
                      to="/admin">Home</Link>
            </Menu.List.Item>
        </Menu.List>
        <Menu.List title="Picks">
            <Menu.List.Item renderAs="div">
                <Link className={(String(window.location.pathname) === "/admin/picks") ? "is-active" : ""}
                      to="/admin/picks">Database</Link>
            </Menu.List.Item>
        </Menu.List>
        <Menu.List title="Students">
            <Menu.List.Item renderAs="div">
                <Link className={(String(window.location.pathname) === "/admin/students") ? "is-active" : ""}
                      to="/admin/students">Database</Link>
            </Menu.List.Item>
            <Menu.List.Item renderAs="div">
                <Link className={(String(window.location.pathname) === "/admin/students/upload") ? "is-active" : ""}
                      to="/admin/students/upload">Bulk Upload</Link>
            </Menu.List.Item>
        </Menu.List>
        <Menu.List title="Carrels">
            <Menu.List.Item renderAs="div">
                <Link className={(String(window.location.pathname) === "/admin/carrels") ? "is-active" : ""}
                      to="/admin/carrels">Database</Link>
            </Menu.List.Item>
            <Menu.List.Item renderAs="div">
                <Link className={(String(window.location.pathname) === "/admin/carrels/upload") ? "is-active" : ""}
                      to="/admin/carrels/upload">Bulk Upload</Link>
            </Menu.List.Item>
            <Menu.List.Item renderAs="div">
                <Link className={(String(window.location.pathname) === "/admin/carrels/export") ? "is-active" : ""}
                      to="/admin/carrels/export">Export</Link>
            </Menu.List.Item>
        </Menu.List>
    </Menu>
)
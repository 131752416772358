import React from "react"
import {Element, Section, Container, Columns, Form, Button, Heading} from "react-bulma-components"
import {StaticQuery, graphql} from "gatsby"
import scrollTo from "gatsby-plugin-smoothscroll"
import Header from "../../../components/Header"
import Page from "../../../components/Page"
import AdminMenu from "../../../components/Admin/AdminMenu"
import UploaderComponent from "../../../components/Admin/Uploader"
import TableComponent from "../../../components/Admin/Table"
import ConfirmationModal from "../../../components/Admin/ConfirmationModal"
import Toast from "../../../components/Admin/Toast"
import {client} from "../../../feathers"

class UploadRoute extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: false,
            message: null,
            show: false,
            data: []
        };
    }

    createCarrels = () => {
        const carrelsService = client.service("carrels");

        this.setState({
            loading: true,
            error: false,
            message: null
        });

        Promise.all([
            carrelsService.create(this.state.data)
        ]).then(([carrelsResult]) => {
            this.setState({
                message: `Successfully loaded ${carrelsResult.length} records into the database`
            })
        }).catch((e) => {
            this.setState({
                error: true,
                message: e.message
            });
        }).finally(() => {
            this.setState({
                loading: false
            }, () => {
                scrollTo("header");
            });
        });
    };

    removeCarrels = () => {
        const carrelsService = client.service("carrels");

        this.setState({
            loading: true,
            error: false,
            message: null
        });

        Promise.all([
            carrelsService.remove(null, {})
        ]).then(([carrelsResult]) => {
            this.setState({
                message: `Successfully removed ${carrelsResult.length} records from the database`
            });
        }).catch((e) => {
            this.setState({
                error: true,
                message: e.message
            });
        }).finally(() => {
            this.setState({
                loading: false,
                show: false
            });
        });
    };

    onCloseToast = () => {
        this.setState({
            error: false,
            message: null
        });
    };

    onToggle = (e) => {
        this.setState({
            [e.target.name]: !this.state[e.target.name]
        });
    };

    onCancel = () => {
        this.setState({
            show: false
        });
    };

    onFileLoaded = ([...data], filename) => {
        this.setState({
            data
        }, () => {
            scrollTo(".footer");
        });
    };

    render() {
        const query = graphql`
            query {
                site {
                    siteMetadata {
                        title
                    }
                }
            }
        `;

        return (
            <>
                <StaticQuery query={query} render={(data) => <Header title="Carrels Uploader"
                                                                     siteName={data.site.siteMetadata.title}/>}/>
                <Page>
                    <Section>
                        <Container>
                            <Columns>
                                <Columns.Column narrow>
                                    <AdminMenu/>
                                </Columns.Column>
                                <Columns.Column className="has-margin-left-40">
                                    <Element className="has-padding-bottom-40">
                                        <Heading renderAs="h2" size={2}>Carrels Bulk Upload</Heading>
                                    </Element>
                                    <UploaderComponent type="Carrels" onFileLoaded={this.onFileLoaded}
                                                       onToggle={this.onToggle} loading={this.state.loading}/>
                                    {(this.state.data && this.state.data.length > 0) && <>
                                        <TableComponent data={this.state.data}/>
                                        <form className="is-flex justify-center" onSubmit={(e) => e.preventDefault()}>
                                            <fieldset>
                                                <Form.Field>
                                                    <Form.Control>
                                                        <Button color="success" onClick={this.createCarrels}
                                                                loading={this.state.loading}
                                                                disabled={this.state.loading}>Save to Carrels
                                                            Database</Button>
                                                    </Form.Control>
                                                </Form.Field>
                                            </fieldset>
                                        </form>
                                    </>}
                                </Columns.Column>
                            </Columns>
                        </Container>
                    </Section>
                    <ConfirmationModal loading={this.state.loading} title="Clear the Carrels Database"
                                       content="Are you sure you want to clear the carrels database?"
                                       confirm="Clear the Database" show={this.state.show} onCancel={this.onCancel}
                                       onConfirm={this.removeCarrels}/>
                    {this.state.message &&
                    <Toast message={this.state.message} error={this.state.error} close={this.onCloseToast}/>}
                </Page>
            </>
        )
    }
}

export default UploadRoute
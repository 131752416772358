import React from "react"
import {connect} from "react-redux"
import {Router} from "@reach/router"
import AdminRoute from "../routes/admin"
import NotFound from "../components/404"
import Index from "../routes/admin/index"
import Picks from "../routes/admin/picks"
import Students from "../routes/admin/students"
import StudentsUpload from "../routes/admin/students/upload"
import Carrels from "../routes/admin/carrels"
import CarrelsUpload from "../routes/admin/carrels/upload"
import CarrelsExport from "../routes/admin/carrels/export"
import {login, hasJWT} from "../actions/authentication"

class AdminPage extends React.Component {
    componentDidMount() {
        if (localStorage && localStorage.getItem(process.env.FEATHERSJS_STORAGE_KEY)) {
            this.props.hasJWT();
        } else {
            this.props.login();
        }
    }

    render() {
        const {reduxState: {authentication: authenticationState}} = this.props;

        return (
            <Router>
                <NotFound default/>
                <AdminRoute path="/admin" component={Index} authenticationState={authenticationState}/>
                <AdminRoute path="/admin/picks" component={Picks} authenticationState={authenticationState}/>
                <AdminRoute path="/admin/students" component={Students} authenticationState={authenticationState}/>
                <AdminRoute path="/admin/students/upload" component={StudentsUpload}
                            authenticationState={authenticationState}/>
                <AdminRoute path="/admin/carrels" component={Carrels} authenticationState={authenticationState}/>
                <AdminRoute path="/admin/carrels/upload" component={CarrelsUpload}
                            authenticationState={authenticationState}/>
                <AdminRoute path="/admin/carrels/export" component={CarrelsExport}
                            authenticationState={authenticationState}/>
            </Router>
        );
    }
}

function mapStateToProps(state) {
    return {
        reduxState: state
    };
}

export default connect(mapStateToProps, {login, hasJWT})(AdminPage)